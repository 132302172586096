import React from "react";
import { Main } from "./components/Main/Main";

export const App = () => {
  return (
    <div>
      <Main />
    </div>
  );
};
